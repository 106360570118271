import React from 'react'
import {Paper, Typography} from '@mui/material'
import {makeStyles} from 'tss-react/mui'
import {Layout} from '../theme/layout'
import {NavigationBar} from '../components/navigation-bar'
import {NavigationBarLink} from '../components/navigation-bar-link'

const useStyles = makeStyles()(() => ({
    root: {
        paddingTop: 110,
        // padding: theme.spacing(2), TODO
        textAlign: 'center',
        maxWidth: '1184px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        padding: 16,
    },
    textSpacing: {
        marginTop: 64,
    },
}))

export default function refusal() {
    const {classes} = useStyles()

    return (
        <Layout>
            <NavigationBar>
                <NavigationBarLink to="/#top">Accueil</NavigationBarLink>
                <NavigationBarLink to="/app">Nouvelle réservation</NavigationBarLink>
                <NavigationBarLink to="/app">Espace client</NavigationBarLink>
            </NavigationBar>

            {/* Body */}
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h3" align="center">
                        Votre paiement a été refusé
                    </Typography>

                    <Typography variant="h5" align="center" gutterBottom className={classes.textSpacing}>
                        Votre transaction a été refusé, nous vous invitons à tenter une nouvelle fois ou à contacter
                        votre banque si
                        le problème persiste.
                    </Typography>
                </Paper>
            </div>
        </Layout>
    )
}
